
import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["element"];
    static values = {
        dependents: String
    }

    connect() {
        // this.outputTarget.textContent = 'Hello, Stimulus!'
    }

    toggle() {
        const dependents = event.currentTarget.getAttribute("data-logic-dependents");
        if (dependents) {
            const dep = dependents.split(" ");
            console.log("deps", dep);
            dep.forEach((id) => {
                const el = document.getElementById(id);
                console.log('el', el);

                if (el.classList.contains("hidden")) {
                    console.log("show", id);
                    el.classList.remove("hidden-completely");
                    el.classList.remove("hidden");
                } else {
                    console.log("hide", id);
                    el.classList.add("hidden");
                    el.classList.add('hidden-completely');
                }
            });
        }
    }
}
