// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import {application} from "./application"

// import { definitionsFromContext } from "stimulus/webpack-helpers"

import LogicController from "./logic_controller"

application.register("logic", LogicController)


// const application = Application.start()
// const context = require.context("controllers", true, /_controller\.js$/)
// application.load(definitionsFromContext(context))
